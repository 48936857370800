<script setup lang="ts">
let {companyImage} = defineProps(['companyImage'])
</script>

<template>
  <div :key="companyImage">
    <img v-if="companyImage" alt="" class="object-cover w-full h-full" :src="companyImage??'~/assets/images/company-images/aphelia.png'"/>
    <img v-else alt="" class="object-cover w-full h-full" src="~/assets/images/company-images/aphelia.png"/>
  </div>
</template>

<style scoped>

</style>